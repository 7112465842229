/***
* Custom variables & overrides to Bootstrap variables
* Note: new custom variables that are not from Bootstrap are prefixed with "td-"
 */

//TODO organize order of the variables and group them better

// Grid breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 768px,
        md: 1024px,
        lg: 1200px,
        xl: 1201px    // xl breakpoint not currently used
) !default;

// Grid containers
$container-max-widths: (
        sm: 100%,
        md: 100%,
        lg: 1200px,
        xl: 1200px
) !default;


// Colors
$white: #fff !default;
$black: #000 !default;

// Theme Palette
$td-colour-primary-light-1:  #008a00 !default;
$td-colour-primary-light-2: #038203 !default;
$td-colour-primary-dark-1: #1a5336 !default;
$td-colour-primary-dark-2: #12422b !default;
$td-colour-secondary: #ff9500 !default;

//TODO optimize gray colours, can we reduce unused ones? Can we improve the colour names?
$td-colour-almost-white: #f9f9f9 !default;
$td-colour-gray-lighter: #f9f9f9 !default;
$td-colour-gray-extra-light: #e1e1e1 !default;
$td-colour-gray-light: #cccccc !default;
$td-colour-gray-middle-light: #bdbdbd !default;
$td-colour-gray-dark: #616161 !default;
$td-colour-almost-black: #1c1c1c !default;

$gray-100: $td-colour-almost-white !default; // equivalent to legacy $almost-white
$gray-200: $td-colour-gray-lighter !default; // equivalent to legacy $gray-lighter
$gray-300: $td-colour-gray-extra-light !default; // equivalent to legacy $gray-extra-light
$gray-400: $td-colour-gray-light !default; // equivalent to legacy $gray-light
$gray-500: $td-colour-gray-middle-light !default; // equivalent to legacy $gray-middle-light
$gray-600: $td-colour-gray-dark !default; // equivalent to legacy $gray-dark
//$gray-700: #495057 !default; // not used, currently same as Bootstrap default
//$gray-800: #343a40 !default; // not used, currently same as Bootstrap default
$gray-900: $td-colour-almost-black !default; // equivalent to legacy $almost-black

// Font Colours
$td-colour-font-dark: #1c1c1c !default;
$td-colour-font-light: #ffffff !default;
$td-colour-font-muted: #767676 !default;

// Messaging Colours
$td-colour-messaging-info: #d8e4d7 !default;
$td-colour-messaging-alert: #fdf2df !default;
$td-colour-messaging-error: #f7e8e9 !default;
$td-colour-messaging-error-dark: #ae1100 !default;

// Button Colours
$td-colour-btn-CTA: $td-colour-secondary !default;
$td-colour-btn-primary-light: $td-colour-primary-light-1 !default;
$td-colour-btn-primary-dark: $td-colour-primary-dark-1 !default;
$td-colour-btn-secondary-clear: $td-colour-primary-light-2 !default;
$td-colour-btn-secondary-clear-white: #ffffff !default;

$td-colour-btn-CTA-hover: #e37609 !default;
$td-colour-btn-CTA-active: #eca42e !default;

//$td-colour-btn-primary-light-border: $td-colour-primary-light-1 !default;
$td-colour-btn-primary-light-hover: #0d7c16 !default;
$td-colour-btn-primary-light-active: #1a9630 !default;

$td-colour-btn-primary-dark-hover: #14422c !default;
$td-colour-btn-primary-dark-active: #30654b !default;

$td-colour-btn-secondary-clear-hover: #ebebeb !default;
$td-colour-btn-secondary-clear-active: #dadada !default;
$td-colour-btn-secondary-clear-border: $td-colour-primary-light-1 !default;
$td-btn-secondary-clear-border-width: 2px !default;


$td-colour-btn-secondary-clear-white-hover: rgba(0,0,0,0.1) !default;
$td-colour-btn-secondary-clear-white-active: rgba(0,0,0,0.2) !default;
$td-btn-secondary-clear-white-border-width: 2px !default;

$td-colour-btn-disabled-color: #ccc !default;
$td-colour-btn-disabled-bg: #f3f3f3 !default;
$td-colour-btn-disabled-border: #dadada !default;
$td-btn-disabled-border-width: 2px !default;

// Icons & Illustrations Colours
$td-colour-icons: $td-colour-primary-dark-1 !default;

// Links & Bullets Colours
$td-colour-links: $td-colour-primary-light-2 !default;
$td-colour-links-inline: $td-colour-primary-light-2 !default;
$td-colour-bullets: $td-colour-primary-light-2 !default;

// Background Colours
$td-colour-bg-1: #f9fcf4 !default;
$td-colour-bg-2: $td-colour-gray-lighter !default;
$td-colour-table-bg-1: #f7fcf8 !default;
$td-colour-table-bg-2: #ebf8ee !default;
$td-colour-bg-6: #f6faf0 !default;
$td-colour-bg-7: #f3f3f3 !default;

// Divider Colours
$td-colour-divider-thick: $td-colour-gray-lighter !default;
$td-colour-divider-1: $td-colour-gray-light !default;
$td-colour-divider-2: $td-colour-gray-dark !default;

// Forms Colours
// TODO re-evaulate use of these variables, are they universal enough to be in the palette or close enough to other colours so they can be substituted?
$td-colour-forms-primary-light: $td-colour-primary-light-1 !default;
$td-colour-forms-gray-light: #dadada;
$td-colour-forms-label-gray: $td-colour-gray-dark;
$td-colour-forms-alert: $td-colour-messaging-error-dark;


//TODO removed colours that don't need to be accessed via a class, create specific custom classes instead
// 1 colour adds approx. 4KB to CSS size
$theme-colors: (
        "primary": $td-colour-primary-dark-1,
        "secondary": $td-colour-secondary,
        "success": $td-colour-messaging-info,
        "info": $td-colour-messaging-info,
        "warning": $td-colour-messaging-alert,
        "danger": $td-colour-messaging-error,
        //"light":
        //"dark":
);

/* Fonts */
$td-font-path: "../fonts/" !default;

$td-font-family-name-light: "TD Graphik Light" !default;
$td-font-filename-light: "TDGraphik-Light-Web" !default;
$td-font-family-name-semilight: "TD Graphik SemiLight" !default;
$td-font-filename-semilight: "TDGraphik-Semilight-Web" !default;
$td-font-family-name-regular: "TD Graphik Regular" !default;
$td-font-filename-regular: "TDGraphik-Regular-Web" !default;
$td-font-family-name-medium: "TD Graphik Medium" !default;
$td-font-filename-medium: "TDGraphik-Medium-Web" !default;
$td-font-family-name-semibold: "TD Graphik Semibold" !default;
$td-font-filename-semibold: "TDGraphik-Semibold-Web" !default;
$td-font-family-name-bold: "TD Graphik Bold" !default;
$td-font-filename-bold: "TDGraphik-Bold-Web" !default;

$td-font-family-name-light-italic: "TD Graphik Light Italic" !default;
$td-font-filename-light-italic: "TDGraphik-LightItalic-Web" !default;
$td-font-family-name-semilight-italic: "TD Graphik SemiLight Italic" !default;
$td-font-filename-semilight-italic: "TDGraphik-SemilightItalic-Web" !default;
$td-font-family-name-regular-italic: "TD Graphik Regular Italic" !default;
$td-font-filename-regular-italic: "TDGraphik-RegularItalic-Web" !default;
$td-font-family-name-medium-italic: "TD Graphik Medium Italic" !default;
$td-font-filename-medium-italic: "TDGraphik-MediumItalic-Web" !default;
$td-font-family-name-semibold-italic: "TD Graphik Semibold Italic" !default;
$td-font-filename-semibold-italic: "TDGraphik-SemiboldItalic-Web" !default;
$td-font-family-name-bold-italic: "TD Graphik Bold Italic" !default;
$td-font-filename-bold-italic: "TDGraphik-BoldItalic-Web" !default;

$font-family-sans-serif: $td-font-family-name-semilight, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$td-font-family-sans-serif-light: $td-font-family-name-light, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$td-font-family-sans-serif-bold: $td-font-family-name-medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

// Secondary Font - optional
$td-use-secondary-font: false !default; // turn on secondary font with this variable

// Secondary Font
$td-secondary-font-family-name-light: $td-font-family-name-light !default;
$td-secondary-font-filename-light: $td-font-filename-light !default;

$td-secondary-font-family-name-semilight: $td-font-family-name-semilight !default;
$td-secondary-font-filename-semilight:  $td-font-filename-semilight !default;

$td-secondary-font-family-name-medium: $td-font-family-name-medium !default;
$td-secondary-font-filename-medium: $td-font-filename-medium !default;

$td-secondary-font-family-name-semibold: $td-font-family-name-medium !default; // mapped to 'medium' since before the North American Branding update 2019, 'TD Graphik Semibold' wasn't used and was actually using 'medium' - see: https://code.td.com/projects/TDOS/repos/td-emerald-standards/browse/src/styles/core/base/_td_variables.scss?at=refs%2Ftags%2Fv1.9.2#103-104
$td-secondary-font-filename-semibold:  $td-font-filename-medium !default; // mapped to 'medium' since before the North American Branding update 2019, 'TDGraphik-Semibold-web' wasn't used and was actually using 'medium' - https://code.td.com/projects/TDOS/repos/td-emerald-standards/browse/src/styles/core/base/_td_variables.scss?at=refs%2Ftags%2Fv1.9.2#103-104

$td-secondary-font-family-name-bold: $td-font-family-name-bold !default;
$td-secondary-font-filename-bold: $td-font-filename-bold  !default;

$td-secondary-font-family-name-regular: $td-font-family-name-regular !default;
$td-secondary-font-filename-regular: $td-font-filename-regular !default;

$td-secondary-font-family-name-light-italic: $td-font-family-name-light-italic !default;
$td-secondary-font-filename-light-italic: $td-font-filename-light-italic !default;

$td-secondary-font-family-name-regular-italic: $td-font-family-name-regular-italic !default;
$td-secondary-font-filename-regular-italic: $td-font-filename-regular-italic !default;

$td-secondary-font-family-name-medium-italic: $td-font-family-name-medium-italic !default;
$td-secondary-font-filename-medium-italic: $td-font-filename-medium-italic !default;

$td-secondary-font-family-name-semilight-italic: $td-font-family-name-semilight-italic !default;
$td-secondary-font-filename-semilight-italic: $td-font-filename-semilight-italic !default;

$td-secondary-font-family-name-semibold-italic: $td-font-family-name-semibold-italic !default;
$td-secondary-font-filename-semibold-italic: $td-font-filename-semibold-italic !default;

$td-secondary-font-family-name-bold-italic: $td-font-family-name-bold-italic !default;
$td-secondary-font-filename-bold-italic: $td-font-filename-bold-italic !default;

// body
$td-body-font-size: 0.9375rem !default; // TD Graphik: 15px (0.9375rem)
$td-body-line-height: 1.5334 !default;  // TD Graphik: 23px (1.5334)

// b, strong
$td-bold-font-family: $td-font-family-sans-serif-bold;

// legal copy & small
$td-legal-copy-font-size: 0.6875rem !default; // TD Graphik: 11px (0.6875rem)
$td-legal-copy-line-height: 1.364 !default; // TD Graphik: 15px (1.364)
$td-small-copy-font-size: 0.8125rem !default; // TD Graphik: 13px (0.8125rem)
$td-small-copy-line-height: 1.385 !default; // TD Graphik: 18px (1.385)

// superscript
$td-superscript-font-size: 0.625rem !default; // TD Graphik: 10px (0.625rem)
$td-superscript-top-position: -0.75em !default;
$td-h1-superscript-font-size: 0.8125rem !default; // TD Graphik: 13px (0.625rem)
$td-h1-superscript-top-position: -1.0rem !default;
$td-h2-superscript-font-size: 0.8125rem !default; // TD Graphik: 13px (0.625rem)
$td-h2-superscript-top-position: -0.75rem !default;
$td-h3-superscript-font-size: 0.8125rem !default; // TD Graphik: 13px (0.625rem)
$td-h3-superscript-top-position: -0.65rem !default;

// Links
$link-color:                $td-colour-links !default;
$link-decoration:           none !default;
$link-hover-decoration:     underline !default;
$link-hover-color:          $td-colour-links !default;
$td-link-inline-color: $td-colour-links-inline !default;
$td-link-inline-hover-color: $td-colour-links-inline !default;

// Body copy action & previous link
$td-link-action-previous-font-family: $td-font-family-sans-serif-bold !default;
$td-link-action-previous-icon-text-shadow: 0.5px 0 0 currentColor, -0.5px 0 0 currentColor, 0 0.5px 0 currentColor, 0 -0.5px 0 currentColor !default;

// h1
$h1-font-size: 1.625rem !default; // TD Graphik: 26px (1.625rem)
$td-h1-font-family: $td-font-family-sans-serif-bold !default;
$td-h1-line-height: 1.3077 !default;  // TD Graphik: 34px (1.3077)
$td-h1-font-size-mobile: 1.625rem !default; // TD Graphik: 26px (1.625rem)
$td-h1-line-height-mobile: 1.3077 !default;  // TD Graphik: 34px (1.3077)
$td-h1-letter-spacing: -0.03125rem !default; // TD Graphik: -0.5px (-0.03125rem)

// h2
$h2-font-size: 1.375rem !default; // TD Graphik: 22px (1.375rem)
$td-h2-font-family: $td-font-family-sans-serif-bold !default;
$td-h2-line-height: 1.41 !default;  // TD Graphik: 31px (1.41)
$td-h2-font-size-mobile: 1.375rem !default; // TD Graphik: 22px (1.375rem)
$td-h2-line-height-mobile: 1.41 !default; // TD Graphik: 31px (1.41)
$td-h2-letter-spacing: -0.03125rem !default; // TD Graphik: -0.5px (-0.03125rem)

// h3
$h3-font-size: 1.125rem !default; // TD Graphik: 18px * .0625
$td-h3-font-family: $td-font-family-sans-serif-bold !default;
$td-h3-line-height: 1.556 !default; // TD Graphik: 28px (1.556)
$td-h3-font-size-mobile: 1.125rem !default; // TD Graphik: 18px * .0625
$td-h3-line-height-mobile: 1.556 !default; // TD Graphik: 28px (1.556)
$td-h3-letter-spacing: -0.03125rem !default; // TD Graphik: -0.5px (-0.03125rem)

// h4, h5, h6
$h4-font-size: 1rem !default;
$h5-font-size: 0.83rem !default; // use CSS default
$h6-font-size: 0.8125rem !default; // absolute minimum font size: 13px

// cta
$td-cta-font-family: $td-font-family-sans-serif-bold !default;
$td-font-size-cta-heading: 1rem !default; // 16px (1rem)
$td-line-height-cta-heading: 1.5 !default;   // TD Graphik: 24px(1.5)
$td-cta-icon-margin-top: -1px !default;
$td-cta-icon-text-shadow: 0.5px 0 0 currentColor, -0.5px 0 0 currentColor, 0 0.5px 0 currentColor, 0 -0.5px 0 currentColor !default;


$line-height-base: 1.375 !default; // 22/16

$headings-line-height: inherit !default;

$hr-border-color: $td-colour-divider-1;
$hr-margin-y: 0;


// Buttons
$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 0.5rem !default;
$input-btn-padding-y-thick-border: 0.4375rem !default;
$input-btn-padding-x-thick-border: 0.4375rem !default;

//$input-btn-line-height: 1.5rem !default;
$btn-font-size: 1rem !default;
$btn-font-family: $td-font-family-sans-serif-bold !default;
$btn-line-height: 1.5rem !default;
$btn-border-radius: 0rem !default;
$btn-box-shadow:              none !default;
$btn-focus-width:             0 !default;
$btn-focus-box-shadow:        none !default;
$btn-disabled-opacity:        1 !default;  // remove Bootstrap's opacity setting, will use colours defined by DCX instead
$btn-active-box-shadow:       none !default;

// Tables
$table-cell-padding:          30px 15px !default;
$table-heading-cell-padding:  15px !default;
$table-border-color:            $td-colour-gray-light !default;

// Icons
$td-use-external-icon-css-file: false !default;   // option to load an external css file for icon class names
$td-icon-css-file-path: "../fonts/icons/icons.css" !default;  // file path for optional external css file for icons
$td-icon-font-path: "../fonts/icons" !default;    // path for icon font files
$td-icon-font-name: "icons" !default;


// Forms
$label-margin-bottom:                   .625rem !default;

//$input-padding-y:                       $input-btn-padding-y !default;
//$input-padding-x:                       $input-btn-padding-x !default;
//$input-line-height:                     $input-btn-line-height !default;
//
//$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
//$input-padding-x-sm:                    $input-btn-padding-x-sm !default;
//$input-line-height-sm:                  $input-btn-line-height-sm !default;
//
//$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
//$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
//$input-line-height-lg:                  $input-btn-line-height-lg !default;
//
$input-bg:                              $white !default;
$input-disabled-bg:                     $td-colour-gray-lighter !default;
//
$input-color:                           $td-colour-font-dark !default;
$input-border-color:                    $td-colour-forms-gray-light !default;
//$input-border-width:                    $input-btn-border-width !default;
$input-box-shadow:                      none !default;
//
$input-border-radius:                   0 !default;
$input-border-radius-lg:                0 !default;
$input-border-radius-sm:                0 !default;
//
//$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              $td-colour-primary-light-1 !default;
//$input-focus-color:                     $input-color !default;
//$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                none !default;
//
$input-placeholder-color:               $td-colour-font-muted !default;
//
//$input-height-border:                   $input-border-width * 2 !default;
//
//$input-height-inner:                    ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
//$input-height:                          calc(#{$input-height-inner} + #{$input-height-border}) !default;
//
//$input-height-inner-sm:                 ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
//$input-height-sm:                       calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;
//
//$input-height-inner-lg:                 ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
//$input-height-lg:                       calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;
//
//$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;


$input-group-addon-color:               $input-color !default;
$input-group-addon-bg:                  none !default;
$input-group-addon-border-color:        $input-border-color !default;

$td-form-control-padding: 3px 15px !default;
$td-radio-checkbox-label-padding: 1px 0 0 0 !default;
$td-radio-checkbox-label-margin: 0 0 8px 0 !default;
$td-radio-checkbox-border-width: 2px !default;

$td-multi-single-select-button-hover-bg: $td-colour-bg-1 !default;
$td-multi-single-select-button-checked-bg: $td-colour-primary-light-2 !default;
$td-multi-single-select-button-focus-outline: #999 !default;
$td-multi-single-select-button-disabled-bg: $td-colour-btn-disabled-bg !default;
$td-multi-single-select-button-disabled-color: $td-colour-btn-disabled-color !default;
$td-multi-single-select-button-disabled-border: $td-colour-btn-disabled-border !default;
$td-multi-single-select-button-border-width: $td-btn-secondary-clear-border-width !default;
$td-multi-single-select-button-border-radius: 0px !default;

///////////////////////////////////////////////////
// new form variables to support new NA branding //
///////////////////////////////////////////////////
$td-form-element-font-family: $td-font-family-sans-serif-bold !default; // $font-family-sans-serif
$td-form-element-font-family-2: $font-family-sans-serif !default;
$td-form-element-border-bottom-width: 3px !default; // 2px
$td-form-element-padding-bottom: 1px !default; // 2px
$td-form-element-form-control-font-size: 0.9375rem !default; // 1rem
$td-form-element-form-control-font-size-xs: 1rem !default;
$td-form-element-form-control-padding: $td-form-control-padding !default; //3px 15px
$td-form-element-form-control-is-invalid-color: $td-colour-forms-alert !default;
$td-form-element-form-control-hover-focus-border-bottom-width: 3px !default; // 2px
$td-form-element-form-control-hover-focus-padding-bottom: 1px !default; // 2px
$td-form-element-form-control-is-invalid-border-bottom-width: 3px !default; // 2px
$td-form-element-form-control-is-invalid-hover-focus-padding-bottom: 2px !default;
$td-form-element-td-error-font-size: 0.8125rem !default; //  0.75rem;
$td-form-element-form-input-placeholder-color: $input-placeholder-color !default; // #656565


// Variables in UI Colour Palette for Devs
// Text
$td-colour-font-1: $td-colour-font-dark;
$td-colour-font-2: $td-colour-primary-dark-1;
$td-colour-font-3: $td-colour-primary-light-2;
$td-colour-font-4: $td-colour-font-light;
$td-colour-font-5: $td-colour-font-muted;
$td-colour-font-6: $td-colour-messaging-error-dark;

// Buttons
$td-colour-btn-1: $td-colour-primary-light-1;
$td-colour-btn-2: $td-colour-secondary;

// Icons
$td-colour-icons-1: $td-colour-icons;

// Backgrounds
$td-colour-bg-1: $td-colour-bg-1;
$td-colour-bg-2: $td-colour-bg-2;
$td-colour-bg-3: $td-colour-primary-light-1;
$td-colour-bg-4: $td-colour-primary-dark-1;
$td-colour-bg-5: $td-colour-font-light;
$td-colour-bg-6: $td-colour-bg-6;
$td-colour-bg-7: $td-colour-bg-7;

// Notification backgrounds
$td-colour-bg-notification-1: $td-colour-messaging-error;
$td-colour-bg-notification-2: $td-colour-messaging-alert;
$td-colour-bg-notification-3: $td-colour-messaging-info;

// Keyline Dividers
$td-colour-divider-1: $td-colour-gray-light !default;
$td-colour-divider-2: $td-colour-gray-dark !default;
