
.td-bg-colour-1 {
  background-color: $td-colour-bg-1;
}

.td-bg-colour-2 {
  background-color: $td-colour-bg-2;
}

.td-bg-colour-3 {
  background-color: $td-colour-bg-3;
}

.td-bg-colour-4 {
  background-color: $td-colour-bg-4;
}

.td-bg-colour-5 {
  background-color: $td-colour-bg-5;
}

.td-bg-colour-6 {
  background-color: $td-colour-bg-6;
}

.td-bg-colour-7 {
  background-color: $td-colour-bg-7;
}