/****
 * THIS FILE IS GENERATED AUTOMATICALLY - DO NOT EDIT DIRECTLY - SEE "build/icons-update.js"
 */

// icon classes from IcoMoon's file style.scss
// wrapped in a mixin to allow the code to be conditionally included in the compiled css

@import "../../fonts/icons/variables";  // use IcoMoon's icon class name variables

// the code within this mixin is copied directly from IcoMoon's style.scss
@mixin td-icon-classes(){

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?smwpca');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?smwpca#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?smwpca') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?smwpca') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?smwpca') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?smwpca##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.td-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.td-icon-overdraftProtection {
  &:before {
    content: $td-icon-overdraftProtection; 
  }
}
.td-icon-mixedPortfolio {
  &:before {
    content: $td-icon-mixedPortfolio; 
  }
}
.td-icon-exit {
  &:before {
    content: $td-icon-exit; 
  }
}
.td-icon-balance {
  &:before {
    content: $td-icon-balance; 
  }
}
.td-icon-dayFinances {
  &:before {
    content: $td-icon-dayFinances; 
  }
}
.td-icon-dynamicPreformance {
  &:before {
    content: $td-icon-dynamicPreformance; 
  }
}
.td-icon-easySafety {
  &:before {
    content: $td-icon-easySafety; 
  }
}
.td-icon-growth {
  &:before {
    content: $td-icon-growth; 
  }
}
.td-icon-growthIncome {
  &:before {
    content: $td-icon-growthIncome; 
  }
}
.td-icon-income {
  &:before {
    content: $td-icon-income; 
  }
}
.td-icon-incomeGrowth {
  &:before {
    content: $td-icon-incomeGrowth; 
  }
}
.td-icon-na {
  &:before {
    content: $td-icon-na; 
  }
}
.td-icon-noSaving {
  &:before {
    content: $td-icon-noSaving; 
  }
}
.td-icon-savePortion {
  &:before {
    content: $td-icon-savePortion; 
  }
}
.td-icon-saveWhenever {
  &:before {
    content: $td-icon-saveWhenever; 
  }
}
.td-icon-slowSteady {
  &:before {
    content: $td-icon-slowSteady; 
  }
}
.td-icon-AccidentSickness {
  &:before {
    content: $td-icon-AccidentSickness; 
  }
}
.td-icon-FileAClaim {
  &:before {
    content: $td-icon-FileAClaim; 
  }
}
.td-icon-GetAQuote {
  &:before {
    content: $td-icon-GetAQuote; 
  }
}
.td-icon-GetAQuote02 {
  &:before {
    content: $td-icon-GetAQuote02; 
  }
}
.td-icon-IRA {
  &:before {
    content: $td-icon-IRA; 
  }
}
.td-icon-Login {
  &:before {
    content: $td-icon-Login; 
  }
}
.td-icon-MBNARewards {
  &:before {
    content: $td-icon-MBNARewards; 
  }
}
.td-icon-TD {
  &:before {
    content: $td-icon-TD; 
  }
}
.td-icon-TDApp {
  &:before {
    content: $td-icon-TDApp; 
  }
}
.td-icon-TDEasyWeb {
  &:before {
    content: $td-icon-TDEasyWeb; 
  }
}
.td-icon-TDShield {
  &:before {
    content: $td-icon-TDShield; 
  }
}
.td-icon-TDdirectInvesting {
  &:before {
    content: $td-icon-TDdirectInvesting; 
  }
}
.td-icon-TDwealth {
  &:before {
    content: $td-icon-TDwealth; 
  }
}
.td-icon-accidentReports {
  &:before {
    content: $td-icon-accidentReports; 
  }
}
.td-icon-accounts {
  &:before {
    content: $td-icon-accounts; 
  }
}
.td-icon-activateCard {
  &:before {
    content: $td-icon-activateCard; 
  }
}
.td-icon-aeroplan {
  &:before {
    content: $td-icon-aeroplan; 
  }
}
.td-icon-all {
  &:before {
    content: $td-icon-all; 
  }
}
.td-icon-allCalculatorTools {
  &:before {
    content: $td-icon-allCalculatorTools; 
  }
}
.td-icon-allProducts {
  &:before {
    content: $td-icon-allProducts; 
  }
}
.td-icon-applyForFunding {
  &:before {
    content: $td-icon-applyForFunding; 
  }
}
.td-icon-approved {
  &:before {
    content: $td-icon-approved; 
  }
}
.td-icon-arrowLeft {
  &:before {
    content: $td-icon-arrowLeft; 
  }
}
.td-icon-arrowRight {
  &:before {
    content: $td-icon-arrowRight; 
  }
}
.td-icon-atm {
  &:before {
    content: $td-icon-atm; 
  }
}
.td-icon-atv {
  &:before {
    content: $td-icon-atv; 
  }
}
.td-icon-auto {
  &:before {
    content: $td-icon-auto; 
  }
}
.td-icon-avatar {
  &:before {
    content: $td-icon-avatar; 
  }
}
.td-icon-balanceTransfer {
  &:before {
    content: $td-icon-balanceTransfer; 
  }
}
.td-icon-banking {
  &:before {
    content: $td-icon-banking; 
  }
}
.td-icon-bath {
  &:before {
    content: $td-icon-bath; 
  }
}
.td-icon-bed {
  &:before {
    content: $td-icon-bed; 
  }
}
.td-icon-bicycle {
  &:before {
    content: $td-icon-bicycle; 
  }
}
.td-icon-bookAppointment {
  &:before {
    content: $td-icon-bookAppointment; 
  }
}
.td-icon-bookAtBranch {
  &:before {
    content: $td-icon-bookAtBranch; 
  }
}
.td-icon-borrowing {
  &:before {
    content: $td-icon-borrowing; 
  }
}
.td-icon-borrowingSolutions {
  &:before {
    content: $td-icon-borrowingSolutions; 
  }
}
.td-icon-branch {
  &:before {
    content: $td-icon-branch; 
  }
}
.td-icon-branchATM {
  &:before {
    content: $td-icon-branchATM; 
  }
}
.td-icon-business {
  &:before {
    content: $td-icon-business; 
  }
}
.td-icon-businessChecklist {
  &:before {
    content: $td-icon-businessChecklist; 
  }
}
.td-icon-businessOwner {
  &:before {
    content: $td-icon-businessOwner; 
  }
}
.td-icon-cantFindTopic {
  &:before {
    content: $td-icon-cantFindTopic; 
  }
}
.td-icon-car {
  &:before {
    content: $td-icon-car; 
  }
}
.td-icon-careers {
  &:before {
    content: $td-icon-careers; 
  }
}
.td-icon-cashBack {
  &:before {
    content: $td-icon-cashBack; 
  }
}
.td-icon-cashFinances {
  &:before {
    content: $td-icon-cashFinances; 
  }
}
.td-icon-cashflow {
  &:before {
    content: $td-icon-cashflow; 
  }
}
.td-icon-cashflowImprovement {
  &:before {
    content: $td-icon-cashflowImprovement; 
  }
}
.td-icon-certificateDeposit {
  &:before {
    content: $td-icon-certificateDeposit; 
  }
}
.td-icon-chat {
  &:before {
    content: $td-icon-chat; 
  }
}
.td-icon-checkMark {
  &:before {
    content: $td-icon-checkMark; 
  }
}
.td-icon-chequeing {
  &:before {
    content: $td-icon-chequeing; 
  }
}
.td-icon-chequeing2 {
  &:before {
    content: $td-icon-chequeing2; 
  }
}
.td-icon-circle {
  &:before {
    content: $td-icon-circle; 
  }
}
.td-icon-claims {
  &:before {
    content: $td-icon-claims; 
  }
}
.td-icon-close {
  &:before {
    content: $td-icon-close; 
  }
}
.td-icon-coinsHigh {
  &:before {
    content: $td-icon-coinsHigh; 
  }
}
.td-icon-coinsLow {
  &:before {
    content: $td-icon-coinsLow; 
  }
}
.td-icon-coinsMiddle {
  &:before {
    content: $td-icon-coinsMiddle; 
  }
}
.td-icon-collapse {
  &:before {
    content: $td-icon-collapse; 
  }
}
.td-icon-communityNonprofit {
  &:before {
    content: $td-icon-communityNonprofit; 
  }
}
.td-icon-condo {
  &:before {
    content: $td-icon-condo; 
  }
}
.td-icon-contactUs {
  &:before {
    content: $td-icon-contactUs; 
  }
}
.td-icon-corporateResponsibility {
  &:before {
    content: $td-icon-corporateResponsibility; 
  }
}
.td-icon-coverage {
  &:before {
    content: $td-icon-coverage; 
  }
}
.td-icon-coverageExtra {
  &:before {
    content: $td-icon-coverageExtra; 
  }
}
.td-icon-coverageMandatory {
  &:before {
    content: $td-icon-coverageMandatory; 
  }
}
.td-icon-coverageModify {
  &:before {
    content: $td-icon-coverageModify; 
  }
}
.td-icon-coverageSpecial {
  &:before {
    content: $td-icon-coverageSpecial; 
  }
}
.td-icon-creditProtection {
  &:before {
    content: $td-icon-creditProtection; 
  }
}
.td-icon-creditcards {
  &:before {
    content: $td-icon-creditcards; 
  }
}
.td-icon-customers {
  &:before {
    content: $td-icon-customers; 
  }
}
.td-icon-dashboard {
  &:before {
    content: $td-icon-dashboard; 
  }
}
.td-icon-debt-en {
  &:before {
    content: $td-icon-debt-en; 
  }
}
.td-icon-debt-fr {
  &:before {
    content: $td-icon-debt-fr; 
  }
}
.td-icon-debtConsolidation {
  &:before {
    content: $td-icon-debtConsolidation; 
  }
}
.td-icon-delete {
  &:before {
    content: $td-icon-delete; 
  }
}
.td-icon-discount {
  &:before {
    content: $td-icon-discount; 
  }
}
.td-icon-distributionGuide {
  &:before {
    content: $td-icon-distributionGuide; 
  }
}
.td-icon-downCaret {
  &:before {
    content: $td-icon-downCaret; 
  }
}
.td-icon-download {
  &:before {
    content: $td-icon-download; 
  }
}
.td-icon-downloadBusinessPlanTemplate {
  &:before {
    content: $td-icon-downloadBusinessPlanTemplate; 
  }
}
.td-icon-downloadCertificate {
  &:before {
    content: $td-icon-downloadCertificate; 
  }
}
.td-icon-earthquake {
  &:before {
    content: $td-icon-earthquake; 
  }
}
.td-icon-economics {
  &:before {
    content: $td-icon-economics; 
  }
}
.td-icon-edit2 {
  &:before {
    content: $td-icon-edit2; 
  }
}
.td-icon-edits {
  &:before {
    content: $td-icon-edits; 
  }
}
.td-icon-email {
  &:before {
    content: $td-icon-email; 
  }
}
.td-icon-employees {
  &:before {
    content: $td-icon-employees; 
  }
}
.td-icon-ensureCompliance {
  &:before {
    content: $td-icon-ensureCompliance; 
  }
}
.td-icon-error {
  &:before {
    content: $td-icon-error; 
  }
}
.td-icon-exchangeRateForCash {
  &:before {
    content: $td-icon-exchangeRateForCash; 
  }
}
.td-icon-exchangeRateForNonCash {
  &:before {
    content: $td-icon-exchangeRateForNonCash; 
  }
}
.td-icon-expand {
  &:before {
    content: $td-icon-expand; 
  }
}
.td-icon-facebook {
  &:before {
    content: $td-icon-facebook; 
  }
}
.td-icon-faqs {
  &:before {
    content: $td-icon-faqs; 
  }
}
.td-icon-feedback {
  &:before {
    content: $td-icon-feedback; 
  }
}
.td-icon-filter {
  &:before {
    content: $td-icon-filter; 
  }
}
.td-icon-financeSmallBusiness {
  &:before {
    content: $td-icon-financeSmallBusiness; 
  }
}
.td-icon-findAcreditcard {
  &:before {
    content: $td-icon-findAcreditcard; 
  }
}
.td-icon-findTaxEfficiencies {
  &:before {
    content: $td-icon-findTaxEfficiencies; 
  }
}
.td-icon-firstVehicle {
  &:before {
    content: $td-icon-firstVehicle; 
  }
}
.td-icon-flexibleMortgageFeatures {
  &:before {
    content: $td-icon-flexibleMortgageFeatures; 
  }
}
.td-icon-forms {
  &:before {
    content: $td-icon-forms; 
  }
}
.td-icon-fraud {
  &:before {
    content: $td-icon-fraud; 
  }
}
.td-icon-generateIncome {
  &:before {
    content: $td-icon-generateIncome; 
  }
}
.td-icon-geolocation {
  &:before {
    content: $td-icon-geolocation; 
  }
}
.td-icon-gicTermDeposits {
  &:before {
    content: $td-icon-gicTermDeposits; 
  }
}
.td-icon-government {
  &:before {
    content: $td-icon-government; 
  }
}
.td-icon-growMyMoney {
  &:before {
    content: $td-icon-growMyMoney; 
  }
}
.td-icon-growYourBusiness {
  &:before {
    content: $td-icon-growYourBusiness; 
  }
}
.td-icon-growingFamily {
  &:before {
    content: $td-icon-growingFamily; 
  }
}
.td-icon-hamburger {
  &:before {
    content: $td-icon-hamburger; 
  }
}
.td-icon-handicap {
  &:before {
    content: $td-icon-handicap; 
  }
}
.td-icon-help {
  &:before {
    content: $td-icon-help; 
  }
}
.td-icon-home {
  &:before {
    content: $td-icon-home; 
  }
}
.td-icon-homeEquityFlexline {
  &:before {
    content: $td-icon-homeEquityFlexline; 
  }
}
.td-icon-homepage {
  &:before {
    content: $td-icon-homepage; 
  }
}
.td-icon-hours {
  &:before {
    content: $td-icon-hours; 
  }
}
.td-icon-house {
  &:before {
    content: $td-icon-house; 
  }
}
.td-icon-howTo {
  &:before {
    content: $td-icon-howTo; 
  }
}
.td-icon-idea {
  &:before {
    content: $td-icon-idea; 
  }
}
.td-icon-identityTheft {
  &:before {
    content: $td-icon-identityTheft; 
  }
}
.td-icon-inbox {
  &:before {
    content: $td-icon-inbox; 
  }
}
.td-icon-information {
  &:before {
    content: $td-icon-information; 
  }
}
.td-icon-insights {
  &:before {
    content: $td-icon-insights; 
  }
}
.td-icon-instagram {
  &:before {
    content: $td-icon-instagram; 
  }
}
.td-icon-insurance {
  &:before {
    content: $td-icon-insurance; 
  }
}
.td-icon-international {
  &:before {
    content: $td-icon-international; 
  }
}
.td-icon-investTradeOnline {
  &:before {
    content: $td-icon-investTradeOnline; 
  }
}
.td-icon-investing {
  &:before {
    content: $td-icon-investing; 
  }
}
.td-icon-investingPlatforms {
  &:before {
    content: $td-icon-investingPlatforms; 
  }
}
.td-icon-investmentTransfers {
  &:before {
    content: $td-icon-investmentTransfers; 
  }
}
.td-icon-investorRelations {
  &:before {
    content: $td-icon-investorRelations; 
  }
}
.td-icon-languagesSpoken {
  &:before {
    content: $td-icon-languagesSpoken; 
  }
}
.td-icon-leftCaret {
  &:before {
    content: $td-icon-leftCaret; 
  }
}
.td-icon-life {
  &:before {
    content: $td-icon-life; 
  }
}
.td-icon-lineOfCredit {
  &:before {
    content: $td-icon-lineOfCredit; 
  }
}
.td-icon-linkedin {
  &:before {
    content: $td-icon-linkedin; 
  }
}
.td-icon-loanCalculator {
  &:before {
    content: $td-icon-loanCalculator; 
  }
}
.td-icon-location {
  &:before {
    content: $td-icon-location; 
  }
}
.td-icon-location-coverage {
  &:before {
    content: $td-icon-location-coverage; 
  }
}
.td-icon-locationFeatures {
  &:before {
    content: $td-icon-locationFeatures; 
  }
}
.td-icon-logout {
  &:before {
    content: $td-icon-logout; 
  }
}
.td-icon-lowInterest {
  &:before {
    content: $td-icon-lowInterest; 
  }
}
.td-icon-maintenance {
  &:before {
    content: $td-icon-maintenance; 
  }
}
.td-icon-makeAMajorPurchase {
  &:before {
    content: $td-icon-makeAMajorPurchase; 
  }
}
.td-icon-manageBusiness {
  &:before {
    content: $td-icon-manageBusiness; 
  }
}
.td-icon-manageMoney {
  &:before {
    content: $td-icon-manageMoney; 
  }
}
.td-icon-mapMarker {
  &:before {
    content: $td-icon-mapMarker; 
  }
}
.td-icon-mapMarkerFilled {
  &:before {
    content: $td-icon-mapMarkerFilled; 
  }
}
.td-icon-marine {
  &:before {
    content: $td-icon-marine; 
  }
}
.td-icon-mortgages {
  &:before {
    content: $td-icon-mortgages; 
  }
}
.td-icon-motorHome {
  &:before {
    content: $td-icon-motorHome; 
  }
}
.td-icon-motorcycle {
  &:before {
    content: $td-icon-motorcycle; 
  }
}
.td-icon-mutualFunds {
  &:before {
    content: $td-icon-mutualFunds; 
  }
}
.td-icon-myAdvantage {
  &:before {
    content: $td-icon-myAdvantage; 
  }
}
.td-icon-myPolicies {
  &:before {
    content: $td-icon-myPolicies; 
  }
}
.td-icon-myTD {
  &:before {
    content: $td-icon-myTD; 
  }
}
.td-icon-navigateVolatility {
  &:before {
    content: $td-icon-navigateVolatility; 
  }
}
.td-icon-newCouple {
  &:before {
    content: $td-icon-newCouple; 
  }
}
.td-icon-newHome {
  &:before {
    content: $td-icon-newHome; 
  }
}
.td-icon-newPage {
  &:before {
    content: $td-icon-newPage; 
  }
}
.td-icon-newToCanada {
  &:before {
    content: $td-icon-newToCanada; 
  }
}
.td-icon-noFee {
  &:before {
    content: $td-icon-noFee; 
  }
}
.td-icon-offers {
  &:before {
    content: $td-icon-offers; 
  }
}
.td-icon-oilDamage {
  &:before {
    content: $td-icon-oilDamage; 
  }
}
.td-icon-onlinePreapproval {
  &:before {
    content: $td-icon-onlinePreapproval; 
  }
}
.td-icon-orderByPhone {
  &:before {
    content: $td-icon-orderByPhone; 
  }
}
.td-icon-orderCashOnline {
  &:before {
    content: $td-icon-orderCashOnline; 
  }
}
.td-icon-orderStatus {
  &:before {
    content: $td-icon-orderStatus; 
  }
}
.td-icon-overlayLink {
  &:before {
    content: $td-icon-overlayLink; 
  }
}
.td-icon-payBillOnline {
  &:before {
    content: $td-icon-payBillOnline; 
  }
}
.td-icon-payBills {
  &:before {
    content: $td-icon-payBills; 
  }
}
.td-icon-payUSBill {
  &:before {
    content: $td-icon-payUSBill; 
  }
}
.td-icon-paymentsTransfers {
  &:before {
    content: $td-icon-paymentsTransfers; 
  }
}
.td-icon-pending {
  &:before {
    content: $td-icon-pending; 
  }
}
.td-icon-person {
  &:before {
    content: $td-icon-person; 
  }
}
.td-icon-personal {
  &:before {
    content: $td-icon-personal; 
  }
}
.td-icon-pinterest {
  &:before {
    content: $td-icon-pinterest; 
  }
}
.td-icon-preciousMetals {
  &:before {
    content: $td-icon-preciousMetals; 
  }
}
.td-icon-preferredAutoVendors {
  &:before {
    content: $td-icon-preferredAutoVendors; 
  }
}
.td-icon-prepaidCard {
  &:before {
    content: $td-icon-prepaidCard; 
  }
}
.td-icon-priceTag {
  &:before {
    content: $td-icon-priceTag; 
  }
}
.td-icon-printer {
  &:before {
    content: $td-icon-printer; 
  }
}
.td-icon-products {
  &:before {
    content: $td-icon-products; 
  }
}
.td-icon-productsAndServices {
  &:before {
    content: $td-icon-productsAndServices; 
  }
}
.td-icon-profitIncrease {
  &:before {
    content: $td-icon-profitIncrease; 
  }
}
.td-icon-question {
  &:before {
    content: $td-icon-question; 
  }
}
.td-icon-quote {
  &:before {
    content: $td-icon-quote; 
  }
}
.td-icon-readyCommitment {
  &:before {
    content: $td-icon-readyCommitment; 
  }
}
.td-icon-registeredAccounts {
  &:before {
    content: $td-icon-registeredAccounts; 
  }
}
.td-icon-registrationPasswords {
  &:before {
    content: $td-icon-registrationPasswords; 
  }
}
.td-icon-renters {
  &:before {
    content: $td-icon-renters; 
  }
}
.td-icon-renters-fr {
  &:before {
    content: $td-icon-renters-fr; 
  }
}
.td-icon-replacementCard {
  &:before {
    content: $td-icon-replacementCard; 
  }
}
.td-icon-requestCertificate {
  &:before {
    content: $td-icon-requestCertificate; 
  }
}
.td-icon-resourceCentre {
  &:before {
    content: $td-icon-resourceCentre; 
  }
}
.td-icon-retirementPlanning {
  &:before {
    content: $td-icon-retirementPlanning; 
  }
}
.td-icon-rightCaret {
  &:before {
    content: $td-icon-rightCaret; 
  }
}
.td-icon-saveForSchool {
  &:before {
    content: $td-icon-saveForSchool; 
  }
}
.td-icon-savingAndInvesting {
  &:before {
    content: $td-icon-savingAndInvesting; 
  }
}
.td-icon-savings {
  &:before {
    content: $td-icon-savings; 
  }
}
.td-icon-search {
  &:before {
    content: $td-icon-search; 
  }
}
.td-icon-secondVehicle {
  &:before {
    content: $td-icon-secondVehicle; 
  }
}
.td-icon-secureBtn {
  &:before {
    content: $td-icon-secureBtn; 
  }
}
.td-icon-selectADate {
  &:before {
    content: $td-icon-selectADate; 
  }
}
.td-icon-sendFeedback {
  &:before {
    content: $td-icon-sendFeedback; 
  }
}
.td-icon-sendMoney {
  &:before {
    content: $td-icon-sendMoney; 
  }
}
.td-icon-sendMoneyInternationally {
  &:before {
    content: $td-icon-sendMoneyInternationally; 
  }
}
.td-icon-share {
  &:before {
    content: $td-icon-share; 
  }
}
.td-icon-shopping {
  &:before {
    content: $td-icon-shopping; 
  }
}
.td-icon-smallBusiness {
  &:before {
    content: $td-icon-smallBusiness; 
  }
}
.td-icon-snowmobile {
  &:before {
    content: $td-icon-snowmobile; 
  }
}
.td-icon-solutions {
  &:before {
    content: $td-icon-solutions; 
  }
}
.td-icon-star {
  &:before {
    content: $td-icon-star; 
  }
}
.td-icon-startClaim {
  &:before {
    content: $td-icon-startClaim; 
  }
}
.td-icon-startOver {
  &:before {
    content: $td-icon-startOver; 
  }
}
.td-icon-startingUp {
  &:before {
    content: $td-icon-startingUp; 
  }
}
.td-icon-startingUp-fr {
  &:before {
    content: $td-icon-startingUp-fr; 
  }
}
.td-icon-startup {
  &:before {
    content: $td-icon-startup; 
  }
}
.td-icon-student {
  &:before {
    content: $td-icon-student; 
  }
}
.td-icon-successionPlanning {
  &:before {
    content: $td-icon-successionPlanning; 
  }
}
.td-icon-superlock {
  &:before {
    content: $td-icon-superlock; 
  }
}
.td-icon-swapLocations {
  &:before {
    content: $td-icon-swapLocations; 
  }
}
.td-icon-switchToTDMortgage {
  &:before {
    content: $td-icon-switchToTDMortgage; 
  }
}
.td-icon-takeAPhoto {
  &:before {
    content: $td-icon-takeAPhoto; 
  }
}
.td-icon-taxFreeAccounts {
  &:before {
    content: $td-icon-taxFreeAccounts; 
  }
}
.td-icon-tdInsuranceCard {
  &:before {
    content: $td-icon-tdInsuranceCard; 
  }
}
.td-icon-tdRewards {
  &:before {
    content: $td-icon-tdRewards; 
  }
}
.td-icon-toOurCustomers {
  &:before {
    content: $td-icon-toOurCustomers; 
  }
}
.td-icon-todaysRates {
  &:before {
    content: $td-icon-todaysRates; 
  }
}
.td-icon-tools {
  &:before {
    content: $td-icon-tools; 
  }
}
.td-icon-tooltip {
  &:before {
    content: $td-icon-tooltip; 
  }
}
.td-icon-trade {
  &:before {
    content: $td-icon-trade; 
  }
}
.td-icon-tradingAccounts {
  &:before {
    content: $td-icon-tradingAccounts; 
  }
}
.td-icon-transfer {
  &:before {
    content: $td-icon-transfer; 
  }
}
.td-icon-transit {
  &:before {
    content: $td-icon-transit; 
  }
}
.td-icon-travel {
  &:before {
    content: $td-icon-travel; 
  }
}
.td-icon-travelRewards {
  &:before {
    content: $td-icon-travelRewards; 
  }
}
.td-icon-truck {
  &:before {
    content: $td-icon-truck; 
  }
}
.td-icon-tutorials {
  &:before {
    content: $td-icon-tutorials; 
  }
}
.td-icon-twitter {
  &:before {
    content: $td-icon-twitter; 
  }
}
.td-icon-unexpectedExpenses {
  &:before {
    content: $td-icon-unexpectedExpenses; 
  }
}
.td-icon-unsureHome {
  &:before {
    content: $td-icon-unsureHome; 
  }
}
.td-icon-upCaret {
  &:before {
    content: $td-icon-upCaret; 
  }
}
.td-icon-usDollar {
  &:before {
    content: $td-icon-usDollar; 
  }
}
.td-icon-vacation {
  &:before {
    content: $td-icon-vacation; 
  }
}
.td-icon-vendor {
  &:before {
    content: $td-icon-vendor; 
  }
}
.td-icon-videoPlayButton {
  &:before {
    content: $td-icon-videoPlayButton; 
  }
}
.td-icon-videoPlayButtonCircle {
  &:before {
    content: $td-icon-videoPlayButtonCircle; 
  }
}
.td-icon-walking {
  &:before {
    content: $td-icon-walking; 
  }
}
.td-icon-warning {
  &:before {
    content: $td-icon-warning; 
  }
}
.td-icon-watchlists {
  &:before {
    content: $td-icon-watchlists; 
  }
}
.td-icon-waterDamage {
  &:before {
    content: $td-icon-waterDamage; 
  }
}
.td-icon-wealthAdvise {
  &:before {
    content: $td-icon-wealthAdvise; 
  }
}
.td-icon-webBroker {
  &:before {
    content: $td-icon-webBroker; 
  }
}
.td-icon-whatsNew {
  &:before {
    content: $td-icon-whatsNew; 
  }
}
.td-icon-wifi {
  &:before {
    content: $td-icon-wifi; 
  }
}
.td-icon-workplace {
  &:before {
    content: $td-icon-workplace; 
  }
}
.td-icon-writeBusinessPlan {
  &:before {
    content: $td-icon-writeBusinessPlan; 
  }
}
.td-icon-youngAndSingle {
  &:before {
    content: $td-icon-youngAndSingle; 
  }
}
.td-icon-youthAccount {
  &:before {
    content: $td-icon-youthAccount; 
  }
}
.td-icon-youtube {
  &:before {
    content: $td-icon-youtube; 
  }
}

}