hr.td-thin-divider-line-1 {
  border-top: 1px solid $td-colour-divider-1;
}

hr.td-thin-divider-line-2 {
  border-top: 1px solid $td-colour-divider-2;
}

hr.td-thick-divider-line {
  border-top: 10px solid $td-colour-divider-thick;
}

.td-vertical-divider-line-1 {
  border-right: solid 1px;
  border-color: $td-colour-divider-1;
}

.td-vertical-divider-line-2 {
  border-right: solid 1px;
  border-color: $td-colour-divider-2;
}
