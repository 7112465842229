// Lists
// --------------------------------------------------

// customize bullets for unordered lists (add class td-list)
ul.td-list {
  list-style: none;
  padding-left: 1.25rem;
  text-align:left;

  > li {
    @include media-breakpoint-only(xs) {
      margin-bottom: 0.3125rem;
    }
    margin-top: 0.3125rem;
    margin-bottom: 0.5rem;

    &:before {
      color: $td-colour-bullets;
      font-family: $td-icon-font-name;
      content: $td-icon-circle;

      font-size: 0.4375rem;
      margin-top: -0.1875rem;
      margin-left: -1.1875rem;
      padding-right: 0.8125rem;
      vertical-align: middle;
      display: inline-block;
    }
  }
}


// formatting for OL (add class td-list)
ol.td-list {
  padding-left: $spacer * (15 / 16);
  text-align:left;

  > li {
    padding-left: $spacer * (8 / 16);

    @include media-breakpoint-only(xs) {
      margin-bottom: $spacer * (5 / 16);
    }
    margin-top: $spacer * (5 / 16);
    margin-bottom: $spacer * (8 / 16);
  }
}
