@charset "UTF-8";

/* ******** Default Font ************ */
@font-face {
  font-family: $td-font-family-name-light;
  src: url('#{$td-font-path}#{$td-font-filename-light}.eot');
  src: url('#{$td-font-path}#{$td-font-filename-light}.eot?#iefix') format('embedded-opentype'),
  url('#{$td-font-path}#{$td-font-filename-light}.woff2') format('woff2'),
  url('#{$td-font-path}#{$td-font-filename-light}.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $td-font-family-name-semilight;
  src: url('#{$td-font-path}#{$td-font-filename-semilight}.eot');
  src: url('#{$td-font-path}#{$td-font-filename-semilight}.eot?#iefix') format('embedded-opentype'),
  url('#{$td-font-path}#{$td-font-filename-semilight}.woff2') format('woff2'),
  url('#{$td-font-path}#{$td-font-filename-semilight}.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $td-font-family-name-regular;
  src: url('#{$td-font-path}#{$td-font-filename-regular}.eot');
  src: url('#{$td-font-path}#{$td-font-filename-regular}.eot?#iefix') format('embedded-opentype'),
  url('#{$td-font-path}#{$td-font-filename-regular}.woff2') format('woff2'),
  url('#{$td-font-path}#{$td-font-filename-regular}.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $td-font-family-name-medium;
  src: url('#{$td-font-path}#{$td-font-filename-medium}.eot');
  src: url('#{$td-font-path}#{$td-font-filename-medium}.eot?#iefix') format('embedded-opentype'),
  url('#{$td-font-path}#{$td-font-filename-medium}.woff2') format('woff2'),
  url('#{$td-font-path}#{$td-font-filename-medium}.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $td-font-family-name-semibold;
  src: url('#{$td-font-path}#{$td-font-filename-semibold}.eot');
  src: url('#{$td-font-path}#{$td-font-filename-semibold}.eot?#iefix') format('embedded-opentype'),
  url('#{$td-font-path}#{$td-font-filename-semibold}.woff2') format('woff2'),
  url('#{$td-font-path}#{$td-font-filename-semibold}.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $td-font-family-name-bold;
  src: url('#{$td-font-path}#{$td-font-filename-bold}.eot');
  src: url('#{$td-font-path}#{$td-font-filename-bold}.eot?#iefix') format('embedded-opentype'),
  url('#{$td-font-path}#{$td-font-filename-bold}.woff2') format('woff2'),
  url('#{$td-font-path}#{$td-font-filename-bold}.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $td-font-family-name-light-italic;
  src: url('#{$td-font-path}#{$td-font-filename-light-italic}.eot');
  src: url('#{$td-font-path}#{$td-font-filename-light-italic}.eot?#iefix') format('embedded-opentype'),
  url('#{$td-font-path}#{$td-font-filename-light-italic}.woff2') format('woff2'),
  url('#{$td-font-path}#{$td-font-filename-light-italic}.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $td-font-family-name-semilight-italic;
  src: url('#{$td-font-path}#{$td-font-filename-semilight-italic}.eot');
  src: url('#{$td-font-path}#{$td-font-filename-semilight-italic}.eot?#iefix') format('embedded-opentype'),
  url('#{$td-font-path}#{$td-font-filename-semilight-italic}.woff2') format('woff2'),
  url('#{$td-font-path}#{$td-font-filename-semilight-italic}.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $td-font-family-name-regular-italic;
  src: url('#{$td-font-path}#{$td-font-filename-regular-italic}.eot');
  src: url('#{$td-font-path}#{$td-font-filename-regular-italic}.eot?#iefix') format('embedded-opentype'),
  url('#{$td-font-path}#{$td-font-filename-regular-italic}.woff2') format('woff2'),
  url('#{$td-font-path}#{$td-font-filename-regular-italic}.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: $td-font-family-name-medium-italic;
  src: url('#{$td-font-path}#{$td-font-filename-medium-italic}.eot');
  src: url('#{$td-font-path}#{$td-font-filename-medium-italic}.eot?#iefix') format('embedded-opentype'),
  url('#{$td-font-path}#{$td-font-filename-medium-italic}.woff2') format('woff2'),
  url('#{$td-font-path}#{$td-font-filename-medium-italic}.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $td-font-family-name-semibold-italic;
  src: url('#{$td-font-path}#{$td-font-filename-semibold-italic}.eot');
  src: url('#{$td-font-path}#{$td-font-filename-semibold-italic}.eot?#iefix') format('embedded-opentype'),
  url('#{$td-font-path}#{$td-font-filename-semibold-italic}.woff2') format('woff2'),
  url('#{$td-font-path}#{$td-font-filename-semibold-italic}.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $td-font-family-name-bold-italic;
  src: url('#{$td-font-path}#{$td-font-filename-bold-italic}.eot');
  src: url('#{$td-font-path}#{$td-font-filename-bold-italic}.eot?#iefix') format('embedded-opentype'),
  url('#{$td-font-path}#{$td-font-filename-bold-italic}.woff2') format('woff2'),
  url('#{$td-font-path}#{$td-font-filename-bold-italic}.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@mixin td-secondary-font() {
  /** Secondary Font **/
  @font-face {
    font-family: $td-secondary-font-family-name-light;
    src: url('#{$td-font-path}#{$td-secondary-font-filename-light}.eot');
    src: url('#{$td-font-path}#{$td-secondary-font-filename-light}.eot?#iefix') format('embedded-opentype'),
    url('#{$td-font-path}#{$td-secondary-font-filename-light}.woff2') format('woff2'),
    url('#{$td-font-path}#{$td-secondary-font-filename-light}.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: $td-secondary-font-family-name-semilight;
    src: url('#{$td-font-path}#{$td-secondary-font-filename-semilight}.eot');
    src: url('#{$td-font-path}#{$td-secondary-font-filename-semilight}.eot?#iefix') format('embedded-opentype'),
    url('#{$td-font-path}#{$td-secondary-font-filename-semilight}.woff2') format('woff2'),
    url('#{$td-font-path}#{$td-secondary-font-filename-semilight}.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: $td-secondary-font-family-name-regular;
    src: url('#{$td-font-path}#{$td-secondary-font-filename-regular}.eot');
    src: url('#{$td-font-path}#{$td-secondary-font-filename-regular}.eot?#iefix') format('embedded-opentype'),
    url('#{$td-font-path}#{$td-secondary-font-filename-regular}.woff2') format('woff2'),
    url('#{$td-font-path}#{$td-secondary-font-filename-regular}.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: $td-secondary-font-family-name-medium;
    src: url('#{$td-font-path}#{$td-secondary-font-filename-medium}.eot');
    src: url('#{$td-font-path}#{$td-secondary-font-filename-medium}.eot?#iefix') format('embedded-opentype'),
    url('#{$td-font-path}#{$td-secondary-font-filename-medium}.woff2') format('woff2'),
    url('#{$td-font-path}#{$td-secondary-font-filename-medium}.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: $td-secondary-font-family-name-semibold;
    src: url('#{$td-font-path}#{$td-secondary-font-filename-semibold}.eot');
    src: url('#{$td-font-path}#{$td-secondary-font-filename-semibold}.eot?#iefix') format('embedded-opentype'),
    url('#{$td-font-path}#{$td-secondary-font-filename-semibold}.woff2') format('woff2'),
    url('#{$td-font-path}#{$td-secondary-font-filename-semibold}.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: $td-secondary-font-family-name-bold;
    src: url('#{$td-font-path}#{$td-secondary-font-filename-bold}.eot');
    src: url('#{$td-font-path}#{$td-secondary-font-filename-bold}.eot?#iefix') format('embedded-opentype'),
    url('#{$td-font-path}#{$td-secondary-font-filename-bold}.woff2') format('woff2'),
    url('#{$td-font-path}#{$td-secondary-font-filename-bold}.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: $td-secondary-font-family-name-light-italic;
    src: url('#{$td-font-path}#{$td-secondary-font-filename-light-italic}.eot');
    src: url('#{$td-font-path}#{$td-secondary-font-filename-light-italic}.eot?#iefix') format('embedded-opentype'),
    url('#{$td-font-path}#{$td-secondary-font-filename-light-italic}.woff2') format('woff2'),
    url('#{$td-font-path}#{$td-secondary-font-filename-light-italic}.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: $td-secondary-font-family-name-semilight-italic;
    src: url('#{$td-font-path}#{$td-secondary-font-filename-semilight-italic}.eot');
    src: url('#{$td-font-path}#{$td-secondary-font-filename-semilight-italic}.eot?#iefix') format('embedded-opentype'),
    url('#{$td-font-path}#{$td-secondary-font-filename-semilight-italic}.woff2') format('woff2'),
    url('#{$td-font-path}#{$td-secondary-font-filename-semilight-italic}.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: $td-secondary-font-family-name-regular-italic;
    src: url('#{$td-font-path}#{$td-secondary-font-filename-regular-italic}.eot');
    src: url('#{$td-font-path}#{$td-secondary-font-filename-regular-italic}.eot?#iefix') format('embedded-opentype'),
    url('#{$td-font-path}#{$td-secondary-font-filename-regular-italic}.woff2') format('woff2'),
    url('#{$td-font-path}#{$td-secondary-font-filename-regular-italic}.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: $td-secondary-font-family-name-medium-italic;
    src: url('#{$td-font-path}#{$td-secondary-font-filename-medium-italic}.eot');
    src: url('#{$td-font-path}#{$td-secondary-font-filename-medium-italic}.eot?#iefix') format('embedded-opentype'),
    url('#{$td-font-path}#{$td-secondary-font-filename-medium-italic}.woff2') format('woff2'),
    url('#{$td-font-path}#{$td-secondary-font-filename-medium-italic}.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: $td-secondary-font-family-name-semibold-italic;
    src: url('#{$td-font-path}#{$td-secondary-font-filename-semibold-italic}.eot');
    src: url('#{$td-font-path}#{$td-secondary-font-filename-semibold-italic}.eot?#iefix') format('embedded-opentype'),
    url('#{$td-font-path}#{$td-secondary-font-filename-semibold-italic}.woff2') format('woff2'),
    url('#{$td-font-path}#{$td-secondary-font-filename-semibold-italic}.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: $td-secondary-font-family-name-bold-italic;
    src: url('#{$td-font-path}#{$td-secondary-font-filename-bold-italic}.eot');
    src: url('#{$td-font-path}#{$td-secondary-font-filename-bold-italic}.eot?#iefix') format('embedded-opentype'),
    url('#{$td-font-path}#{$td-secondary-font-filename-bold-italic}.woff2') format('woff2'),
    url('#{$td-font-path}#{$td-secondary-font-filename-bold-italic}.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }
}

@if $td-use-secondary-font == true { @include td-secondary-font(); }