// Links
// For specs on link styling please check "DCX Rules & Strategies - Link Style Usage": https://www.tdstandards.com/res/latest/DCX_rules_strategies.html#Tab_5

// Inline Links - links that are within paragraphs/body-copy, bold with no underline (underline on hover)
// - is applied to links in paragraph, <p><a>link</a></p>
// - is also applied to superscript and subscript links, <sup><a>link</a></sup>
// - is also applied to links in lists, <ul><li><a>link</a></li></ul>
// - can also manually apply the styling with 'td-link-inline' class
p a,
sup a,
sub a,
ul.td-list a,
ol.td-list a,
dl a,
a.td-link-inline {
  font-weight: bold;
  font-family: $td-font-family-sans-serif-bold;

  // if a special colour is defined specifically for inline links then add the below rules, otherwise don't
  // note: in Sapphire we define a special colour for inline links to meet accessibility requirements (3:1 contrast between inline links and surrounding text)
  @if ($td-link-inline-color != $link-color) {
    color: $td-link-inline-color;
    &:hover, &:focus {
      color: $td-link-inline-hover-color;
    }
  }
}

a.td-link-nounderline {
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: none;
  }
}

// Standalone Links - links with arrows
.td-link-action,
.td-link-previous {
  // fix IE11 bug where underline appears on hover under pseudo element (icon) even if we don't want it (see: https://stackoverflow.com/questions/27018420/how-to-remove-an-underline-on-a-pseudo-element/32120247)
  &:hover::before, &:hover::after { text-decoration: underline; } // we must set the underline to appear first
}
a.td-link-action,
a.td-link-previous {
  position: relative;
  font-family: $td-link-action-previous-font-family;

  &:before, &:after {
    font-size: 70%;
    font-family: $td-icon-font-name;
    vertical-align: middle;
    line-height: 1;
    width: 1em; // set width equal to font size
    text-align: center; // center the icon so there's equal padding on both sides
    display: inline-block;  // allow sizing to be changed
  }

  // fix IE11 bug where underline appears on hover under pseudo element (icon) even if we don't want it (see: https://stackoverflow.com/questions/27018420/how-to-remove-an-underline-on-a-pseudo-element/32120247)
  &:hover::before, &:hover::after { text-decoration: none; }      // hide the underline (after we've set it to appear above), this is an IE11 bug
}

a.td-link-action::after {
  margin-left: 4px;
  content: $td-icon-rightCaret;
}

a.td-link-previous::before {
  margin-right: 6px;
  margin-left: -2px;
  position: relative;
  top: -.1em;
  content: $td-icon-leftCaret;
}

//.td-makeclickable {
//  cursor: pointer;
//}

// CTA link - note this is the same as the h3.td-cta link, only this can be used without h3
a.td-cta {
  font-family: $td-cta-font-family;
  font-size: $td-font-size-cta-heading;
  line-height: $td-line-height-cta-heading;

  &:hover {
    //text-decoration: underline;
  }

  // size the arrow icon if .td-cta is used with .td-link-action to add the arrow icon
  &.td-link-action::after {
    font-size: $td-font-size-cta-heading;
    margin-top: $td-cta-icon-margin-top;
  }

  > .td-icon {
    display: inline-block;
    vertical-align: middle;
    margin-top: $td-cta-icon-margin-top;
  }
}

// standalone + CTA links arrow icon bold (text-shadow is used to make the icon bold for Emerald)
@if ($td-cta-icon-text-shadow != none){
  a.td-cta > .td-icon,
  h3.td-cta > a > .td-icon{
    font-weight: normal; // set font-weight to normal as we already use text-shadow to make the icon bolder
    text-shadow: $td-cta-icon-text-shadow;
  }
}
@if ($td-link-action-previous-icon-text-shadow != none){
  a.td-link-action::after,
  a.td-link-previous::before,
  a.td-link-standalone .td-link-lastword .td-icon::before,
  a.td-link-back .td-link-firstword > .td-icon::before {
    font-weight: normal; // set font-weight to normal as we already use text-shadow to make the icon bolder
    text-shadow: $td-link-action-previous-icon-text-shadow;
  }
}
