//
// Emerald styling to Tables
// --------------------------------------------------

// default tables (unbordered)
.table {
  th {
    font-family: $td-font-family-sans-serif-bold;  // custom font for bold header
  }
  > thead > tr > th {
    border-bottom: 1px solid $table-border-color;
  }
  // heading row bg colour
  > thead th {
    background-color: $td-colour-gray-lighter;
  }

  // customized paddings
  > thead > tr > th,
  > thead > tr > td {
    padding: $table-heading-cell-padding;
  }

  // class to vertically align
  &.td-table-align-middle {
    td, th {
      vertical-align: middle;
    }
  }
}

// bordered tables
.table-bordered {
  // Cancel Bootstrap's side borders around entire table
  border-left: 0;
  border-right: 0;

  // Cancel Bootstrap's thicker border for thead
  > thead > tr > th,
  > thead > tr > td {
    border-bottom: 1px solid $table-border-color;
  }

  // first column
  td:first-child,
  th:first-child{
    border-left: 0;
  }

  // last column
  td:last-child,
  th:last-child{
    border-right: 0;
  }
}

// Bootstrap responsive table wrapper
.table-responsive {
  @include media-breakpoint-only(xs) {
    border: none; //remove Bootstrap border
  }
}
