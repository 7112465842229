// body, custom font, font size, and line height
body{
  font-size: $td-body-font-size;
  line-height: $td-body-line-height;
  font-variant-ligatures: no-contextual;  // set to 'no-contextual' to fix TD Graphik font bug, where the combination of numbers followed by space and the % character, adds the double-prime character in place of the space (in Firefox and Safari) - see: https://track.td.com/browse/TDSEB4-48
}

// bold, use a custom bold font
b,
strong {
  font-family: $td-bold-font-family;
}

// sup
sup {
    font-size: $td-superscript-font-size;
    top: $td-superscript-top-position;
    vertical-align: baseline;
    position: relative;
}

// Headings
h1, .h1 {
  font-family: $td-h1-font-family;
  line-height: $td-h1-line-height;
  color: $td-colour-primary-dark-1;
  letter-spacing: $td-h1-letter-spacing;

  @include media-breakpoint-only(xs) {
    @if ($td-h1-font-size-mobile != $h1-font-size) {
      font-size: $td-h1-font-size-mobile;
    }
    @if ($td-h1-line-height-mobile != $td-h1-line-height) {
      line-height: $td-h1-line-height-mobile;
    }
  }

  // Headings - superscript
  sup {
    font-size: $td-h1-superscript-font-size;
    top: $td-h1-superscript-top-position;
  }
}

h2, .h2 {
  font-family: $td-h2-font-family;
  line-height: $td-h2-line-height;
  color: $td-colour-primary-dark-1;
  letter-spacing: $td-h2-letter-spacing;

  @include media-breakpoint-only(xs) {
    @if ($td-h2-font-size-mobile != $h2-font-size) {
      font-size: $td-h2-font-size-mobile;
    }
    @if ($td-h2-line-height-mobile != $td-h2-line-height) {
      line-height: $td-h2-line-height-mobile;
    }
  }

  // Headings - superscript
  sup {
    font-size: $td-h2-superscript-font-size;
    top: $td-h2-superscript-top-position;
  }
}

h3, .h3 {
  font-family: $td-h3-font-family;
  line-height: $td-h3-line-height;
  letter-spacing: $td-h3-letter-spacing;

  @include media-breakpoint-only(xs) {
    @if ($td-h3-font-size-mobile != $h3-font-size) {
      font-size: $td-h3-font-size-mobile;
    }
    @if ($td-h3-line-height-mobile != $td-h3-line-height) {
      line-height: $td-h3-line-height-mobile;
    }
  }

  // Headings - superscript
  sup {
    font-size: $td-h3-superscript-font-size;
    top: $td-h3-superscript-top-position;
  }
}

.td-legal-copy {
  font-size: $td-legal-copy-font-size;
  line-height: $td-legal-copy-line-height;
}

.td-small-copy {
  font-size: $td-small-copy-font-size;
  line-height: $td-small-copy-line-height;
}