
.td-notification, .td-notification-section {
  padding: 10px 0px 14px 0px;
  &.bg-danger {
    h3 {
      color: $td-colour-forms-alert;
    }
  }
  &.bg-success {
    h3 {
      color: $td-colour-primary-dark-1;
    }
  }
  h3 {
    display: flex;
    align-items: flex-start;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.td-notification-section {padding: 10px 0px 14px 0px;margin:0px}

.td-notification h3 > span.td-icon, .td-notification-section h3 > span.td-icon {font-size:30px;margin-right:16px;}
